@import url('https://fonts.googleapis.com/css2?family=Quicksand');


body {
  font-family: 'Quicksand';
}
.App {
  text-align: center;
}

.leaflet-container {
  width: 100%;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.leaflet-container {
  position: absolute;
  top: 65px;
  height: calc(100% - 65px);
}

.navbar {
  background-color: #e9f0f4!important;
  padding: 0px;
  align-items: unset;
  position: absolute;
  top: 0px;
  width: 100%;
  height: 65px;
  z-index: 450;
  box-shadow: 4px 4px 6px 0px #00000061;
}

button:focus {
  outline: unset!important;
}

.button-logo {
  margin: 0;
  padding: 12px;
  border: none;
  background: none;
}

.button-logo:hover{
  background-color: #d1e1e9;
}

.button-logo:focus{
  background-color: #d1e1e9;
}


.listlogo {
  height: auto;
  width: 45px;
}
.navbar-pag {
  border-left: 3px solid #d1e1e9;
  border-right: 3px solid #d1e1e9;
  padding-left: 20px;
  color: #526f7f;
  line-height: 28px;
  width: 300px;

}

.sidebar-div {
  z-index: 500;
  position: fixed;
  height: calc(100% - 65px);
  top: 65px;
  width: 368px;
  transition: all 1s;
  background-color: #e9f0f4cc;
  box-shadow: 4px 4px 6px 0px #00000061;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1, p {
  font-family: 'Quicksand';
}

.sidebar-nav-menu-item{
   display:block;
}
.sidebar-nav-menu-item-body{
  /*This is equivalent to display: none*/
  overflow: hidden; 
  height: 0; 
  opacity: 0;
  transition: height 0ms 400ms, opacity 400ms 0ms;
  padding-left: 20px;
}



.sidebar-nav-menu-item.item-active .sidebar-nav-menu-item-body{
  /*This is equivalent to display: block*/
  height: auto; 
  opacity: 1;
  transition: height 0ms 0ms, opacity 600ms 0ms;
}

.sidebar-nav-menu-item-head-help{
  /*To move button in header to right*/
  position: absolute;
  right: 35px;
}

.sidebar-nav-menu-item-head{
  /*To style header*/
  background-color: #e9f0f4;
  color: #526f7f;
  padding: 20px;
  border-top: 2px solid #d1e1e9;
  position: relative;
  font-size: 16px;
}

.sidebar-nav-menu-item-head span:hover {
  color: #1d292f;
  font-weight: 600;
}

.item-active .sidebar-nav-menu-item-head {
  color: #1d292f;
  font-weight: 600;
  background-color: #d1e1e9;
  border-top-color: #a1adb3;
  display: flex;
  flex-wrap: nowrap;
  justify-content: normal;
}

.komunalna-infrastruktura .sidebar-nav-menu-item-body{
  overflow-y: auto;
  max-height: 60vh;
}


input[type=checkbox] {
  margin-right: 10px;
}

.leaflet-left{
  position: absolute;
  top: unset!important;
  left: unset!important;
  right: 0px!important;
  bottom: 20px!important;
}

.span_icon {
  margin-right: 15px;
  margin-left: 5px;
  cursor: pointer;
}
.sidebar-nav-menu-item-head-title {
  cursor: pointer;
  flex-basis: 90%;
}

.head-sidebarcontent{
  cursor: pointer;
}

.head-sidebarcontent:hover{
  font-weight: 600;
}

.body-sidebarcontent{
  display: none;
  margin-left: 20px;
}

#icon_slide {
  position: absolute;
  z-index: 500;
  top: calc(100% - 50vh);
  line-height: 50px;
  height: 50px;
  background-color: #2e6ca445;
  box-shadow: 6px -1px 6px 0px #00000061;
  border: 1px solid #2e6ca48a;
  right: -17px;
  border-left: unset;
  cursor: pointer;
}

#icon_slide:hover {
  background-color: #ffffffcc;
}

.item-display {
  display: block;
}

form label:hover{
  font-weight: 600;
}

label {
  cursor: pointer;
  margin: auto;
}

.checkbox-div {
  margin-bottom: 5px;
}

.buttonabsolute {
  position: absolute;
  z-index: 9999;
  bottom: 100px;
}

div .buttonabsolute:last-child {
  left: 100px;
}

.tooltip-css.leaflet-tooltip-right:before, .ekiTooltip.leaflet-tooltip-right:before {
  border: none;
  background: none;
}
.leaflet-tooltip-right:before {
  border-right-color: #fff0;
}

.leaflet-tooltip {
  border: none;
  background: rgb(255 255 255 / 51%);
  padding: 2px;
  box-shadow: none;
  font-weight: 700;
}

.tooltip-css .leaflet-tooltip-right {
  margin-left: 10px;
}

.roadTooltip {
  color: red!important;
  background-color: #f5efe7!important;
  border: 1px solid #c2ab98;
}

.ekiTooltip {
  background-color: #dac5da94!important;
  border: 1px solid #b4a4a4;
}


.roadTooltip.leaflet-tooltip-right:before {
  border-right-color: #c2ab98!important;
}

.left-sidebar {
  width: 0%;
}

.nav-display {
  opacity: 0;
}


/* Customize the Clustered Markers */

.marker-cluster-custom-1 {
  background: #1f78b4;
  border: 3px solid #ffffff81;
  border-radius: 50%;
  color: white;
  height: 40px;
  line-height: 37px;
  text-align: center;
  width: 40px;
  cursor: default;
}

.marker-cluster-custom-2 {
  background: #e84c54;
  border: 3px solid #c4091280;
  border-radius: 50%;
  color: white;
  height: 40px;
  line-height: 37px;
  text-align: center;
  width: 40px;
}
.marker-cluster-custom-3 {
  background: #18af18;
  border: 3px solid rgba(90, 90, 90, 0.397);
  border-radius: 50%;
  color: white;
  height: 40px;
  line-height: 37px;
  text-align: center;
  width: 40px;
}

.marker-cluster-custom-4 {
  background: #d53fc9;
  border: 3px solid rgba(90, 90, 90, 0.397);
  border-radius: 50%;
  color: white;
  height: 40px;
  line-height: 37px;
  text-align: center;
  width: 40px;
}

.marker-cluster-custom-5 {
  background: rgb(32, 32, 32);
  border: 3px solid rgba(201, 198, 198, 0.397);
  border-radius: 50%;
  color: white;
  height: 40px;
  line-height: 37px;
  text-align: center;
  width: 40px;
}
.marker-cluster-custom-6 {
  background: #32c7c5;
  border: 3px solid rgba(90, 90, 90, 0.397);
  border-radius: 50%;
  color: white;
  height: 40px;
  line-height: 37px;
  text-align: center;
  width: 40px;
}

.marker-cluster-custom-7 {
  background: #f5ee30;
  border: 3px solid rgba(90, 90, 90, 0.397);
  border-radius: 50%;
  color: black;
  height: 40px;
  line-height: 37px;
  text-align: center;
  width: 40px;
}

:checked + span {
  font-weight: bold;
}
/*
BASELAYERI
*/
.baselayers {
  display: flex;
  flex-basis: 100%;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
}

.mapBaseLayers{
  border: unset;
  background-color: unset;
  color: #2e6ca4;
  cursor: pointer;
  flex-basis: 33%;
  width: 3vw;
  height: 65px;
}
.mapBaseLayers:hover {
  background-color: #d1e1e9;
}

.mapBaseLayers svg {
  width: 48px!important;
  height: 28px!important;
}

.radiobutton span {
  margin-left: 5px;
}
/*input[type="checkbox"] { 
  filter: invert(100%) hue-rotate(18deg) brightness(1.7);
}*/

/*
PANEL
*/
.mypanel {
  position: absolute;
  top: 65px;
  right: 145px;
  box-shadow: 0px 0px 9px 2px #00000061;
}

/*
LEAFLET LAYERS CONTROL
*/

.leaflet-control-layers {
  display: none;
}

.paglogo a img {
  height: 38px!important;
}

.panelMap {
  display: none;
}

.ikona_tablica {
  font-size: 15px;
}

.tablica_button {
  background: unset;
  border: unset;
}

.tablica_button:hover {
  font-weight: 800;
  color: white;
  border-radius: 5px;
  background-color: #1d292f;
}

/*
MODAL
*/
.eki-modal .modal-body {
  padding: 0px;
}
.eki-modal .modal-dialog {
  max-width: 100%!important;
  width: 97%!important;
  position: fixed;
  bottom: 0px;
  right: 2px;
  left: 2px;
  margin-bottom: 4px;
}

.modal-backdrop.show {
  opacity: unset;
}
.modal-backdrop {
  background-color: unset;
  height: unset;
}
.modal-open .modal {
  height: unset!important;
}

.modal-information .modal-dialog{
  width: 500px;
}

.modal-information .modal-footer {
  justify-content: space-between;
}
.modal-information .modal-footer .grey{
  background-color: rgb(92, 88, 88)!important;
  color: white;
  border: 1px solid rgb(56, 53, 53)!important;
}
.modal-information .modal-footer .grey:hover{
  background-color: rgb(56, 53, 53)!important;
  color: white;
}
.modal-information .modal-footer .buton-datatable:hover{
  background-color: #e9f0f4!important;
  color: rgb(35, 38, 226)!important;
}

.modal-information .modal-footer .buton-datatable:hover a{
  color: rgb(35, 38, 226)!important;
}
.eki-modal .modal-content {
  height: 40vh;
  overflow: hidden;
  transition: all 1s;
}

.eki-modal .modal-title {
  line-height: unset;
  font-size: 16px;
}

.eki-modal .modal-header {
  padding: 7px;
}

/*POPUP NERAZ CESTE*/
.leaflet-popup-content {
  width: 250px;
  text-align: center;
}

.popup-span {
  color: #2e6ca4;
  font-weight: 600;
  font-variant-caps: unset;
  font-size: 12px;
  text-transform: none;
  word-break: break-word;
}

.popup-p {
  font-weight: 600;
  word-break: break-word;
  font-size: 10px;
  text-transform: uppercase;
}

.header-popup {
  height: 23px;
  background-color: #2e6ca4;
  margin-bottom: 0px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.divcontent {
  border: 1px solid #2e6ca459;
  padding-right: 10;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.divcontent p:first-child { 
  margin-top: 5px;
}

/*
Datatable
*/

.table-down {
  width: 40px;
  background-color: #e9f0f4;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  border: 1px solid #2e6ca4;
}

.table-down:hover {
  background-color: #2e6ca4;
  color: white;
}

.buton-datatable {
  font-weight: 700;
  background-color: #2e6ca4;
  color: #d1e1e9;
  border: 1.5px solid #d1e1e9;
  border-radius: 5px;
  cursor: pointer;
  height: 32px;
  line-height: 16px;
  margin-right: 2px;
}

.buton-datatable:hover {
  background-color: #d1e1e9;
  color: #2e6ca4;
}

.buton-datatable:disabled {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  cursor: unset;
}

.button-div-datatable {
  width: 100%;
  padding-top: 5px;
  flex-basis: 100%;
  right: 5px;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  background: white;
  font-size: 14px;
}

.forma-datatable:hover label {
  font-weight: 500;
  cursor: unset;
}

.forma-datatable input {
  width: 50px;
}

.forma-datatable label {
  margin-left: 5px;
  margin-right: 5px;
}

.my-datatable {
  width: 100%;
  height: 30vh;
}

.my-datatable thead {
  color: #526f7f;
  border-bottom: 2px solid #526f7f3d;
}

.my-datatable thead th { 
  width: fit-content;
  position: sticky;
  top: -5px;
  background: white;
  font-size: 15px;
}
.my-datatable th:hover {
  cursor: pointer;
  color: black;
}

.my-datatable tr, .my-datatable td {
  padding: 5px;
  cursor: pointer;
}
.my-datatable tr:hover {
  background-color: #526f7f3d;
}

.my-datatable thead tr:first-child {
  background-color: unset;
  cursor: unset;
}

.my-datatable tr{
  border-bottom: 1px solid #526f7f3d;
}

.my-datatable tr:last-child {
  border: none;
}

.my-datatable tr td {
  width: 10%;
  margin-left: 2px;
  margin-right: 2px;
  word-wrap: break-word;
}
.ceste tbody {
  font-size: 14px;
}
.dowbload-upute {
  color: white;
}
.vlas {
  word-break: break-word!important;
}
.ceste tr td {
  width: 7%;
}

.ceste tr td:last-child {
  width: 14%;
  word-break: break-word;
  margin-right: 5px;
}

.ceste tr td:nth-child(7) {
  width: 11%;
  margin-right: 5px;
  word-break: break-word;
}
.onhover-span:hover {
  font-weight: 600;
}

.eki tr td:nth-child(2) {
  width: 6%;
}
.eki tr td:nth-child(3), .eki tr td:nth-child(7), .eki tr td:nth-child(8), .eki tr td:nth-child(9) {
  width: 15%;
  word-break: break-word;
}
.a-datatable {
  margin-right: 5px;
}

.div-eki {
  padding: 5px;
  max-height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 29vh;
}

.eki tbody {
  font-size: 14px;
}

.footer-modal-eki {
  display: flex;
  flex-basis: 100%;
  align-items: baseline;
  flex-direction: row;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
}

.footer-modal-buttons {
  display: flex;
  position: absolute;
  right: 0px;
}

.datatable-input {
  font-weight: 500;
  margin-right: 10px;
}
.datatable-input:hover {
  font-weight: 500;
}

.datatable-input label:hover {
  cursor: unset;
}

.datatable-input label {
  margin-right: 5px;
  color: #2e6ca4;
  font-weight: 600;
}

.datatable-input input {
  outline: unset!important;
}

.datatable-input select {
  height: 4vh;
  background-color: #2e6ca4;
  color: #d1e1e9;
  border: 1.5px solid #d1e1e9;
  border-radius: 5px;
  padding-right: 4px;
  padding-left: 4px;
  outline: unset!important;
}

.datatable-input:hover select {
  cursor: pointer;
}

.eki-modal .modal-dialog-hide {
  height: 5vh;
}

.table-close {
  cursor: pointer;
}

.popup-a {
  margin-left: 5px;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
}

/*IPAD*/

@media screen and (max-width: 780px) {
  .footer-modal-eki {
    justify-content: space-between;
    padding-top: 20px;
  }
  .datatable-input input {
    width: 150px;
  }
  .footer-modal-buttons {
    position: relative;
  }
}

.showBicikl {
  display: block;
}

.hideBicikl {
  display: none;
}

.bicikli-sidebar {
  cursor: pointer;
  
}

.showBicikl form {
  margin-left: 30px;
}

/**/
.leaflet-bar a {
  background-color: #2e6ca4;
  color: white;
}

.leaflet-bar a:hover {
  background-color: #e9f0f4;
  color: #2e6ca4;
}

.leaflet-control {
  border: 2px solid #e9f0f4!important;
}

