.login-page {
    height: 100vh;
    background: url("../img/pozadina_pag.jpg");
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-position: center;
    background-repeat: no-repeat;
    width: 100vw;
    text-align: center;
    display: flex;
    justify-content: center;
    color: white;
    background-size: cover;

    transition: transform 0.6s;
    transform-style: preserve-3d;
}
.logo-login-page p {
    font-size: 20px;
}
.logo-login-page h3 {
    margin-top: 20px;
}

.logo-login-page {
    flex-basis: 100%;
    height: fit-content;
}

.login-forma  {
    position: absolute;
    text-align: center;
    width: 350px;
    opacity: 0;
    display: none;
}

.div-login input {
    font: 15px/24px "Lato", Arial, sans-serif;
    color: white;
    width: 100%;
    border: 1px solid #ccc;
    padding: 7px 14px;
    transition: 0.4s;
    background: transparent;
    box-sizing: border-box;
    letter-spacing: 1px;
}


.div-login label {
    position: absolute;
    left: 145px;
    top: 10px;
    color: white;
    transition: 0.3s;
    z-index: -1;
    letter-spacing: 0.5px;
}

.label-first {
    left: 120px!important;
}

.login-forma input {
    border-radius: 5px;
    border: 1px solid white;
}

.login-forma input:focus {
   outline: unset;
   box-shadow: 0px 5px 20px 0px white;
}

.div-login {
    position: relative;
    width: 350px;
    text-align: left;
}

.first {
    margin-bottom: 25px;
}

.div-login span {
    font-size: 11px;
    color: #ffcc00;
    font-weight: 600;
}


.login-forma label:hover {
    cursor: unset;
    font-weight: 600;
}

.login-forma label input:hover {
    border: 2px solid white;
	background-color:#ccdfeb;
    box-shadow: 0px 9px 14px 0px #ffffff54;
}

.login-button {
    box-shadow: 0px 9px 14px 0px #ffffff54;
	background:linear-gradient(to bottom, #c6d1d4 5%, #afd5db 100%);
	background-color:#599bb3;
	border-radius:8px;
	display:inline-block;
	cursor:pointer;
	color:#1a597d;
	padding: 8px 10px;
	text-decoration:none;
	text-shadow:0px 1px 0px #053450;
    border: 1px solid #ffffff33;
    margin-top: 10px;
}

.login-button:hover {
	background:linear-gradient(to bottom, #12608d 5%, #96b0be 100%);
	background-color:#1a597d;
    color: white;
}
.login-button:active {
	position:relative;
	top:1px;
}

.logo-login-page {
    position: absolute;
    margin: auto;
    width: 350px;
    text-align: center;
    transition: transform 1s;
    top: 25vh;
}

.fade-in-translate {
    animation: mymove 3s;
    top: 60vh; 
    opacity: 1;
    display: block;
}

.transition-logo {
    animation:  fadeInOut 1.5s linear forwards;
}
.transition-logo button {
    display: none;
}

.div-login input:focus ~ label, .has-content ~ label {
    top: -18px;
    left: 5px!important;
    font-size: 12px;
    box-shadow: #afd5db;
    color: #ffffff;
    transition: 0.3s;
}

@keyframes fadeInOut {
    from {top:25vh;}
    to {top: 12vh;}
}

@keyframes mymove { 
    from {opacity: 0;}
    to {opacity: 1}
}